import React, { useState, useEffect } from "react";
import { getCookie } from '../../auth/helpers';
import Layout from '../../core/Layout';
import CustomToolbar from "./../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {
  Dialog,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {datatableTheme} from "../../components/datatable-theme.js";
import { ToastContainer, toast } from 'react-toastify';

//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from 'axios';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Countries = () => {
  const token = getCookie('token');
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [formTitle, setFormTitle] = useState("Add Country");
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items

  useEffect(() => {
    const fetchData = async () => {
      await axios(`${process.env.REACT_APP_BACKEND}/api/countries`, {
        responseType: "json",
        headers: {
            Authorization: `Bearer ${token}`
        },
      }).then((response) => {
        setItems(response.data)
        setItemsBackup(response.data)
        return setIsLoading(false)
      });
    };
    fetchData();
  }, [open]);
  
  const columns = [
    {
      name: "id",
      options: { display: false}
    },
    {
      name: "name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <a href="#" onClick={e => e.preventDefault()} onClick={() => {
                  handleAdd("Edit Country - "+tableMeta.rowData[2],tableMeta.rowData[0]);
                }}>{value}</a>
        },
      },
    },
    {name: "code"},
    {name: "phoneNumber",label: "Phone Number"}
  ];

  const options = {
    filter: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return <CustomToolbar listener={() => {
        handleAdd("Add Country");
      }} />;
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map(d => items[d.dataIndex].serial); // array of all ids to to be deleted
        axios.delete(`${process.env.REACT_APP_BACKEND}/api/countries/${idsToDelete}`, {
          responseType: "json",
          headers: {
              Authorization: `Bearer ${token}`
          },
        }).then((response) => {
          setItems(response.data.data)
          toast.success(response.data.Message);
        });
    },
    textLabels: {
        body: {
            noMatch: !isLoading && 'Sorry, there is no matching data to display'
        },
    }
  };
  
  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, countriesId) => {
    if(countriesId){
      await axios(`${process.env.REACT_APP_BACKEND}/api/countries/${countriesId}`, {responseType: "json",
      headers: {
          Authorization: `Bearer ${token}`
      }}
      ).then((response) => {
        setFormValues(response.data);
        setFormTitle(title)
      }).then((response) => {
        setOpen(true);
      });
    }else{
      setFormValues({
        image: "",
        name: "",
        text: "",
        link: ""
      });
      setFormTitle(title)
      setOpen(true);
    }
    //setCountriesId(countriesId);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //Search component ---------------END--------------
  return (<Layout>
    <ToastContainer />
    <div className="m-2 pt-5">

      {!isLoading ? (
        <MuiThemeProvider theme={datatableTheme}>
          <MUIDataTable
            title=""
            data={items}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      ) : (
        <CircularProgress size={30} className="pageLoader" />
      )}

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AddFormDialog
            title={formTitle}
            handleClose={handleClose}
            formValues={formValues}
          />
        </Dialog>
      </div>
    </div>
    </Layout>
  
  );
}
export default Countries