import React from 'react'
import {BrowserRouter,Switch,Route} from 'react-router-dom'
import Signin from './auth/signin'
import PrivateRoute from './auth/PrivateRoutes'
import Private from './core/Private'
import Candidates from './pages/Candidates/Candidates'
import Countries from './pages/Countries/Countries'

const Routes = () => {
    return <BrowserRouter>
    <Switch>
        <Route path="/" exact component={Signin} />
        <PrivateRoute path="/private" exact component={Private} />
        <PrivateRoute path="/candidates" exact component={Candidates} />
        <PrivateRoute path="/countries" exact component={Countries} />
    </Switch>
    </BrowserRouter>
}

export default Routes;