import React, { useState, useEffect } from "react";
import { getCookie } from '../../auth/helpers';
import Layout from '../../core/Layout';
import CustomToolbar from "./../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../components/datatable-theme.js";
import {
  Dialog,
  Slide,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from "@material-ui/lab";

import FilterComponent from "./Components/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from 'axios';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Candidates = () => {
  const token = getCookie('token');
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [formTitle, setFormTitle] = useState("Add Candidate");
  const [countriesList, setCountriesList] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [itemsBackup, setItemsBackup] = useState([]);
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [filterDialog,setFilterDialog] = useState(false);
  const [pagingInfo, setPagingInfo] = useState({page:0,limit:20,skip:0,count:20}); //Pagination Info


    
  useEffect(() => {
    const fetchData = async () => {
        await axios.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/candidates?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,{headers: {Authorization: `Bearer ${token}`}}),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/countries`,{headers: {Authorization: `Bearer ${token}`}}),
        ]).then(response => {
          setItems(response[0].data)
          if(itemsBackup.length===0) setItemsBackup(response[0].data)
          setCountriesList(response[1].data)
          return setIsLoading(false)
        })
    };
    fetchData();
  }, [open,pagingInfo.page,pagingInfo.limit,searchEntry]);
  
  const columns = [
    {
      name: "id", options: { display: false}
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <a href="#" onClick={e => e.preventDefault()} onClick={() => {
                  handleAdd("Edit Candidate - "+tableMeta.rowData[2],tableMeta.rowData[0]);
                }}>{value}</a>
        },
      },
    },
    {
      name: "fatherName", label: "Father Name"
    },
    {
      name: "lastName", label: "Last Name"
    },
    {name: "country",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const countryInfo=countriesList.find(e=>e.id==value)
        return countryInfo?countryInfo.name:"-"
      },
    },},
    {name: "phoneCode",options: {display: false}},
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{tableMeta.rowData[5]} {value}</>
        },
      },
    },
    {name: "email",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return <a href={`mailto:${value}`}>{value}</a>
      },
    },},
    {name: "hobby"},
    {name: "rate"},
  ];

  const options = {
    filter: false,
    onRowsDelete: null,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return <CustomToolbar handleFilter={handleFilter} />;
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map(d => items[d.dataIndex].serial); // array of all ids to to be deleted
        axios.delete(`${process.env.REACT_APP_BACKEND}/api/candidates/${idsToDelete}`, {
          responseType: "json",
          headers: {
              Authorization: `Bearer ${token}`
          },
        }).then((response) => {
          setItems(response.data.data)
          toast.success(response.data.Message);
        });
    },
    textLabels: {
        body: {
            noMatch: !isLoading && 'Sorry, there is no matching data to display'
        },
    }
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };
  
  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, candidatesId) => {
    if(candidatesId){
      await axios(`${process.env.REACT_APP_BACKEND}/api/candidates/${candidatesId}`, {responseType: "json",
      headers: {
          Authorization: `Bearer ${token}`
      }}
      ).then((response) => {
        setFormValues(response.data);
        setFormTitle(title)
      }).then((response) => {
        setOpen(true);
      });
    }else{
      setFormValues({
        image: "",
        name: "",
        text: "",
        link: ""
      });
      setFormTitle(title)
      setOpen(true);
    }

    //setCandidatesId(candidatesId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    if(newValue) setSearchEntry(newValue); else setItems(itemsBackup)
  }
  //Search component ---------------END--------------
  return (<Layout>
    <ToastContainer />
    <div className="m-2">
    <Autocomplete
      // multiple
      freeSolo
      // limitTags={3}
      id="tags-standard"
      options={[]}
      getOptionLabel={(option) => option}
      onChange={handleChangeSearch}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder="Search Data"
          label="Filter by First Name and Last Name"
        />
      )}
    />

      {!isLoading ? (
        <MuiThemeProvider theme={datatableTheme}>
          <MUIDataTable
            title=""
            data={items}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      ) : (
        <CircularProgress size={30} className="pageLoader" />
      )}

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AddFormDialog
            title={formTitle}
            handleClose={handleClose}
            formValues={formValues}
            setFormValues={setFormValues}
            countriesList={countriesList}
          />
        </Dialog>

        <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} setItems={setItems} setPagingInfo={setPagingInfo} pagingInfo={pagingInfo} />
        </Dialog>

      </div>
    </div>
    </Layout>
  
  );
}
export default Candidates