import React, { useState, useEffect } from "react";
import {
  TextField,DialogContent, DialogActions} from "@material-ui/core";
import { Button, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import axios from 'axios';
import { Close, Save } from "@material-ui/icons";
import { getCookie } from '../../../auth/helpers';



const FilterComponent = (props) => {
  const token = getCookie('token');
  const [countriesList, setCountriesList] = useState([]);

  const [formValues, setFormValues] = useState({country:""});



  useEffect(() => {
    const fetchData = async () => {
        await axios.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/countries`,{headers: {Authorization: `Bearer ${token}`}}),
        ]).then(response => {
          setCountriesList(response[0].data)
        })
    };
    fetchData();
  }, []);


  
  const handleChangeAutoComplete= (e, newValue, name) =>{
    if(newValue) setFormValues({ ...formValues, [name]: newValue });
  }
  const handleChangeTextfield = (e) => {
    const {name, value} = e.target
    setFormValues({ ...formValues, [name]: value });
  }

  const handleOnSubmit = async () => {
    let filterArray= []
    if(formValues.country.id) filterArray.push(`country=${formValues.country.id}`)
    if(formValues.firstName) filterArray.push(`firstName=${formValues.firstName}`)
    if(formValues.fatherName) filterArray.push(`fatherName=${formValues.fatherName}`)
    if(formValues.lastName) filterArray.push(`lastName=${formValues.lastName}`)
    if(formValues.hobby) filterArray.push(`hobby=${formValues.hobby}`)
    if(formValues.rate) filterArray.push(`rate=${formValues.rate}`)
    await axios(
      `${process.env.REACT_APP_BACKEND}/api/candidates?${filterArray.join('&')}`,{responseType: "json", headers: {Authorization: `Bearer ${token}`}}
      ).then(function (response) {
        props.setPagingInfo({...props.pagingInfo,count:response.data.count});
        props.setItems(response.data)
        props.setOpenDialog(false)
    })
    .catch((error) => {
      console.log(error);
    }); 
  }
return <>
    
      <DialogContent dividers><h3>Candidates Filter</h3></DialogContent>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="CountryInput"
              options={countriesList || {}}
              value={formValues.country || {}}
              getOptionLabel={(option) => Object.keys(option).length!==0 ? option.name : ""}
              fullWidth
              onChange={(e,newValue)=>handleChangeAutoComplete(e,newValue,"country")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  name="country"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            onChange={(handleChangeTextfield)}
          />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField
            id="fatherName"
            name="fatherName"
            label="Father Name"
            type="text"
            fullWidth
            onChange={(handleChangeTextfield)}
          />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            onChange={(handleChangeTextfield)}
          />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField
            id="hobby"
            name="hobby"
            label="Hobby"
            type="text"
            fullWidth
            onChange={(handleChangeTextfield)}
          />
          </Grid>
          <Grid item xs={12} sm={4}>
          <TextField
            id="rate"
            name="rate"
            label="Rate"
            type="text"
            fullWidth
            onChange={(handleChangeTextfield)}
          />
          </Grid>
        </Grid>
        </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className="btn btn--save"
          onClick={() => props.setOpenDialog(false)}
          startIcon={<Close />}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="btn btn--save"
          onClick={handleOnSubmit}
          startIcon={<Save />}
        >
          Save
        </Button>
      </DialogActions>
        
</>
};

export default FilterComponent;
