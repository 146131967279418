import React from "react";
import {AppBar,Toolbar} from "@material-ui/core";
import { getCookie } from '../../../auth/helpers';
import { useForm } from "react-hook-form";
import axios from 'axios';

const AddFormDialog = (props) => {
  const token = getCookie('token');
  const { register, handleSubmit } = useForm();
  const handleChangeRate = (e) => {
    props.setFormValues({...props.formValues,rate:e.target.value})
  }
  const handleOnSubmit = async (data) => {
    let dataToSave={...props.formValues,rate:data.rate}
    delete dataToSave.id
    delete dataToSave.created_at
    delete dataToSave.updated_at
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND}/api/candidates/${props.formValues.id}`,
      headers: {Authorization: `Bearer ${token}`},
      data: dataToSave,
    })
    .then((response) => {
      props.handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <AppBar className="position-relative">
      <Toolbar>
      <button type="button" className="closeBtn" onClick={props.handleClose}>&times;</button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
      </Toolbar>
      </AppBar>
      <div style={{ padding: "50px" }}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-4 mb-4"><strong>First Name:</strong> {props.formValues.firstName}</div>
            <div className="col-sm-4 mb-4"><strong>Father Name:</strong> {props.formValues.fatherName}</div>
            <div className="col-sm-4 mb-4"><strong>Last Name:</strong> {props.formValues.lastName}</div>
            <div className="col-sm-4 mb-4"><strong>Country:</strong> {props.countriesList.find(e=>e.id==props.formValues.country)?props.countriesList.find(e=>e.id==props.formValues.country).name:"-"}
            </div>
            <div className="col-sm-4 mb-4"><strong>Phone Number:</strong> {props.formValues.phoneCode} {props.formValues.phoneNumber}</div>
            <div className="col-sm-4 mb-4"><strong>Email:</strong> <a href={`mailto:${props.formValues.email}`}>{props.formValues.email}</a></div>
            <div className="col-sm-4 mb-4"><strong>Hobby:</strong> {props.formValues.hobby}</div>
            </div>
            <div class="row justify-content-sm-center">
              <div className="col-sm-4 mb-4 text-center">
                <video width="320" height="240" controls>
                  <source src={`http://fame.dsof-lb.net/backend/storage/app/videos/${props.formValues.video}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> 
              </div>
            </div>
            <div class="row justify-content-sm-center">
              <div className="col-sm-4 mb-4 text-center">
                <strong>Rate Video:</strong> {props.formValues.rate}<br/>
                <input type="range" class="form-range" min="0" max="10" step="0.1" {...register("rate")} value={props.formValues.rate} onChange={handleChangeRate}></input>
              </div>
            </div>
            <div class="row">
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Save</button> &nbsp;&nbsp; 
              <button type="button" className="btn btn-outline-secondary" onClick={props.handleClose}><i className="fas fa-times"></i> Close</button>
            </div>

            
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
