import { createTheme } from "@material-ui/core/styles";
export const datatableTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      toolButton: {
        minWidth: "100px",
        margin: "10px 20px",
      },
      data:{
        textTransform: "capitalize"
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        position: "absolute",
        top: "-50px",
        left: 0,
        right: 0,
        paddingTop:0,
        paddingBottom:0
      },
    },
    MUIDataTableBodyCell: {
      root: {
        minWidth: "100px",
        margin: "5px 20px",
      },
    },
    MUIDataTableToolbar: {
      root: {
        width: "50%",
        position: "absolute",
        right: 0,
        top: "-59px",
      },
    },
    MUIDataTable: {
      paper: {
        position: "relative",
        height: "calc(100vh - 13rem)",
      },
      responsiveBase: {
        height: "100% !important",
        width: "100% !important",
        overflow: "scroll",
      }
    },
    MUIDataTableFooter:{
      root:{
        position: "fixed",
        left: 0,
        bottom: 0
      }
    }
  },
});