import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Layout from '../core/Layout';
import axios from 'axios';
import { authenticate, isAuth } from './helpers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Signin = ({ history }) => {
    const [values, setValues] = useState({
        email: '',
        password: '',
        buttonText: 'Sign in'
    });

    const { email, password, buttonText } = values;

    const handleChange = name => event => {
        // console.log(event.target.value);
        setValues({ ...values, [name]: event.target.value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, buttonText: 'Signing in' });
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BACKEND}/api/login`,
            data: { email, password }
        })
            .then(response => {
                console.log('SIGNIN SUCCESS', response);
                // save the response (user, token) localstorage/cookie
                authenticate(response, () => {
                    setValues({ ...values, name: '', email: '', password: '', buttonText: 'Signed in' });
                    // toast.success(`Hey ${response.data.user.name}, Welcome back!`);
                    isAuth() && isAuth().role === 'admin' ? history.push('/admin') : history.push('/candidates');
                });
            })
            .catch(error => {
                // console.log('SIGNIN ERROR', error.response.data);
                setValues({ ...values, buttonText: 'Signin' });
                // toast.error(error.response.data.error);
            });
    };

    const signinForm = () => (
        <form>
            <div className="form-group pb-4">
                <label className="text-muted">Email</label>
                <input onChange={handleChange('email')} value={email} type="email" className="form-control" />
            </div>

            <div className="form-group pb-4">
                <label className="text-muted">Password</label>
                <input onChange={handleChange('password')} value={password} type="password" className="form-control" />
            </div>

            <div className="pb-5">
                <button className="btn btn-primary navbar-right" onClick={clickSubmit} style={{float:"right"}}>
                    {buttonText}
                </button>
            </div>
        </form>
    );

    return (
        <Layout>
            <div className="col-md-4 offset-md-4 mt-5 signInCont">
                <ToastContainer />
                {isAuth() ? <Redirect to="/" /> : null}
                <div className="text-center py-5"><img src="images/logo.jpg" style={{maxHeight:50}} /></div>
                {/* <h3 className="py-2 text-center">Signin</h3> */}
                {signinForm()}
                {/* <br />
                <Link to="/auth/password/forgot" className="btn btn-sm btn-outline-danger">
                    Forgot Password
                </Link> */}
            </div>
        </Layout>
    );
};

export default Signin;