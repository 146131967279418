import React from 'react'
import {Link,withRouter} from 'react-router-dom'
import { isAuth,signout } from '../auth/helpers';

const Layout = ({ children, match, history }) => {
    const isActive = path => {
        if(match.path===path){
            return { color: "#000"}
        }else{
            return { color: "#fff"}
        }
    }
    const nav = () => (
<nav className="navbar navbar-expand-lg navbar-light bg-light">
<div className="container-fluid">
  <a className="navbar-brand" href="#"><img src="images/logo.jpg" style={{maxHeight:50}} /></a>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarText">
    <div className="navbar-nav w-100 mb-2 mb-lg-0">
            {!isAuth()?<>
            <div className="ms-auto nav-item">
                <Link to="/" className="text-dark nav-link" style={isActive('/')}><strong>Signin</strong></Link>
            </div>
            </>:<>
            <div className="nav-item">
                <Link to="/countries" className="text-dark nav-link" style={isActive('/countries')}>Countries</Link>
            </div>
            <div className="nav-item">
                <Link to="/candidates" className="text-dark nav-link" style={isActive('/candidates')}>Candidates</Link>
            </div>
            <div className="ms-auto nav-item" style={{cursor:"pointer"}}>
                <span onClick={() => signout(()=>history.push("/"))} className="text-dark nav-link" ><strong>Signout</strong></span>
            </div>
            </>}
    </div>
  </div>
</div>
</nav>


    )
    return <>
        {isAuth()?nav():null}
        <div className="container-fluid">
            {children}
        </div>
    </>
}

export default withRouter(Layout);