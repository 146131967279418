import React from "react";
import {AppBar,Toolbar} from "@material-ui/core";
import { getCookie } from '../../../auth/helpers';
import { useForm } from "react-hook-form";
import axios from 'axios';

const AddFormDialog = (props) => {
  const token = getCookie('token');
  const { register, handleSubmit } = useForm();
  
  const handleOnSubmit = async (data) => {

    if (props.title.substring(0, 4)==="Edit") {
        await axios({
          method: "put",
          url: `${process.env.REACT_APP_BACKEND}/api/countries/${props.formValues.id}`,
          headers: {
              Authorization: `Bearer ${token}`
          },
          data: data,
        })
        .then((response) => {
          props.handleClose();
          })
          .catch((error) => {
            console.log(error);
          });
    } else {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND}/api/countries`,
          headers: {
              Authorization: `Bearer ${token}`
          },
          data:data,
        })
          .then((response) => {
            props.handleClose()
          })
          .catch((error) => {
            console.log(error);
          });
      }
  
  }
  return (
    <>
      <AppBar className="position-relative">
      <Toolbar>
      <button type="button" className="closeBtn" onClick={props.handleClose}>&times;</button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
      </Toolbar>
      </AppBar>
      <div style={{ padding: "50px" }}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">

            <div className="col-sm-6 pb-4">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" placeholder="Title" {...register("name")} defaultValue={props.formValues.name} />
              </div>
            </div>
            <div className="col-sm-6 pb-4">
              <div className="form-group">
                <label htmlFor="nameAr">Name Arabic</label>
                <input type="text" className="form-control" id="nameAr" placeholder="Name Arabic" {...register("nameAr")} defaultValue={props.formValues.nameAr} />
              </div>
            </div>
            <div className="col-sm-6 pb-4">
              <div className="form-group">
                <label htmlFor="code">Code</label>
                <input type="text" className="form-control" id="code" placeholder="Code" {...register("code")} defaultValue={props.formValues.code} />
              </div>
            </div>
            <div className="col-sm-6 pb-4">
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input type="text" className="form-control" id="phoneNumber" placeholder="Phone Number" {...register("phoneNumber")} defaultValue={props.formValues.phoneNumber} />
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Save</button> &nbsp;&nbsp; 
              <button type="button" className="btn btn-outline-secondary" onClick={props.handleClose}><i className="fas fa-times"></i> Close</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
