import React from 'react'
import Layout from '../core/Layout'

const Private = () => {
    return <Layout>


    </Layout>
}

export default Private;